/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
    font-size: 10px;
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
/* You can add global styles to this file, and also import other style files */

/* 
    Chrome, Safari, Edge, Opera 
    Hide up and down arrow for number field
*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* 
    Firefox 
    Hide up and down arrow for number field
*/
input[type="number"] {
    -moz-appearance: textfield;
}

.jk-debug {
    border: 2px solid red;
}
body.mat-typography {
    margin: 0;

    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: #000;

    /* override default fonts */
    .mat-step-label,
    input.mat-input-element,
    .mat-select-value-text,
    textarea.mat-input-element {
        font-size: 1.6rem;
    }
    .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
        color: #fff;
    }

    a {
        color: #000;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }

    table {
        width: 100%;
    }

    form {
        // margin: 2rem;
    }
    .mat-drawer-content{
        width: 100%;
    }

    .jk-page-header,
    .jk-section-header {
        margin: 3rem;
        text-transform: capitalize;
    }

    .jk-error {
        background-color: #f44336;
        padding: 2rem 5rem;
        width: fit-content;
    }

    .jk-card-container {
        .jk-highlight {
            background-color: rebeccapurple;
        }

        /* Search Results */
        .mat-card {
            border-radius: 25px;
            padding: 0;
        }
        .mat-card-header {
            .mat-card-title,
            .mat-card-subtitle {
                text-align: left;
            }
        }

        .mat-card-content {
            text-align: left;
        }

        .mat-card-image {
            width: 330px;
            height: 350px;
            margin: 0;
        }
    }

    /* View Profile */

    .mat-dialog-content {
        li {
            margin: 1.1rem 0;
            font-size: 1.3rem;
            letter-spacing: 1px;
        }
        .jk-vp-confirm-qn {
            font-size: 1.6rem;
            margin: 2.4rem;
            line-height: 2.5rem;
        }
    }
    // .jk-vp-card {
    //     .jk-vp-content-container {
    //     }
    // }

    /* Photo Viewer Dialog */
    .jk-pv-mat-dialog-container {
        .mat-dialog-container {
            background-color: #000;
            opacity: 98%;
        }
    }

    /* Snack Bar */
    .mat-snack-bar-container {
        color: #fff;
        font-size: 1.5rem;

        &.jk-notification-error {
            background-color: #f44336;
        }

        &.jk-notification-warn {
            background-color: orange;
        }

        &.jk-notification-info {
            background-color: #396739;
        }

        &.jk-notification-success {
            background-color: #396739;
        }
    }

    .jk-form-container {
        margin-top: 3rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .jk-form-field-container {
            width: 100%;
            .mat-mdc-form-field {
                width: 100%;
            }
        }

        &.jk-double-column-form {
            .jk-form-field-container {
                width: 45%;
                .mat-mdc-form-field {
                    width: 100%;
                }
            }
        }

        .jk-form-field-error {
            color: #fff;
            margin-bottom: 1rem;
            background-color: #f44336;
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
        }

        .jk-form-buttons {
            width: 87%;
            text-align: center;
            .mat-raised-button {
                margin: 1rem 2rem 0 0;
            }
        }
        .jk-link {
            text-decoration: underline;
            cursor: pointer;
            margin-left: 0.5rem;
        }

        /* Mobile */
        &.jk-xs {
            margin-top: 1rem;
            flex-direction: column;
            .jk-form-field-container {
                width: 100%;
            }
            .mat-form-field {
                width: 95%;
            }
        }
    }
    /* Search Results */
    .jk-xs {
        .jk-card-container {
            max-width: 35rem;
            width: 35rem;
            .mat-card-title {
                // font-size: 1.5rem;
            }
        }
        .mat-card-image {
            width: 35rem;
            height: 35rem;
        }
    }

    /** Admin */

    .moa-right-nav-items {
        a {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 0.75rem 1.5rem;
            box-sizing: border-box;
            vertical-align: middle;
            align-items: center;
            &:hover {
                background-color: #303030;
                text-decoration: none;
            }
            .mat-icon {
                margin-right: 0.5rem;
            }
        }
    }
     /* Highlight Links */
     .active {
        border-bottom: 1px solid red;
    }
} // Body
